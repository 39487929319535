<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            Permissions
            <template slot="actions">
                <mercur-button :to="{ 'name': 'AccessControlAddPermission' }" class="btn btn-raised btn-yellow text-uppercase" v-if="isAllowedTo('createPermissions')">
                    +
                    <span>Add Permission</span>
                </mercur-button>
            </template>
        </grid-header>
        <data-table
            class="fill full-height-layout border"
            ref="table"
            :options="options"
            :url="url"
            :quickSearch="filters.search"
            v-if="isAllowedTo('listPermissions')"
        />

        <p class="permission-message" v-else>Not allowed to see this view</p>

        <div>
            <mercur-dialog :is-open.sync="dialog.isOpen">
                <div slot="default">{{ dialog.content }}</div>
                <div slot="footer">
                    <mercur-button class="btn" @click.native="dialog = { isOpen: false }" :disabled="loading">Cancel</mercur-button>
                    <mercur-button class="btn btn-primary" @click.native="revoke" :disabled="loading">Confirm</mercur-button>
                </div>
            </mercur-dialog>
        </div>
    </mercur-card>
</template>

<script>
import GridHeader from '../../components/GridHeader'
import CONFIG from '@root/config'
import DataTable from '../../components/DataTable'

export default {
    name: 'AccessControlPermissionsOverview',
    components: { GridHeader, DataTable },
    data () {
        return {
            url: CONFIG.API.ROUTES.ACCESS_CONTROL.PERMISSIONS.OVERVIEW,
            filters: {
                search: '',
            },
            isDialogOpen: false,
            dialog: {
                isOpen: false,
            },
            loading: false,
            options: {
                columns: {
                    'Permission Name': {
                        field: 'permissionName',
                    },
                },
                actions: [
                    {
                        text: 'Edit',
                        icon: 'fas fa-edit',
                        to: ({ data }) => {
                            return {
                                name: 'AccessControlEditPermission',
                                params: {
                                    permissionId: data.permissionId,
                                },
                            }
                        },
                        disabled: () => {
                            return this.loading || !this.isAllowedTo('updatePermissions')
                        },
                    },
                    {
                        text: 'Delete',
                        icon: 'fas fa-trash',
                        onClick: ({ data }) => {
                            this.triggerRevokeDialog(data)
                        },
                        disabled: () => {
                            return this.loading || !this.isAllowedTo('revokePermissions')
                        },
                    },
                ],
                quickSearchColumns: ['permissionName'],
            },
        }
    },
    methods: {
        triggerRevokeDialog (data) {
            this.dialog = {
                isOpen: true,
                content: `Are you sure that you want to delete this permission?`,
                data: data,
            }
        },
        revoke () {
            if (!this.isAllowedTo('revokePermissions')) {
                return
            }

            const url = CONFIG.API.ROUTES.ACCESS_CONTROL.PERMISSIONS.REVOKE
            this.loading = true
            this.$api.post(url, { id: this.dialog.data.permissionId }).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Permission was revoked`,
                })
                this.$refs.table.refreshGrid()
                this.dialog = {
                    isOpen: false,
                }
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: `Revoking failed. Please try again.`,
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
    },
}
</script>
